import { LoadingIcon } from '@meterup/atto';

export function FullscreenFallback() {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoadingIcon color={{ light: 'iconNeutralLight', dark: 'iconNeutralDark' }} size={24} />
    </div>
  );
}
