import { datadogRum } from '@datadog/browser-rum';
import { injectGlobalAttoStyles } from '@meterup/atto';
import { AuthorizationProvider, DemoModeProvider } from '@meterup/authorization';
import { Command } from '@meterup/command';
import {
  darkThemeSelector,
  Notifications,
  ResourceNotFoundError,
  useSetMaximumScale,
} from '@meterup/common';
import { getGraphQLError, GraphQLExtensionErrorCode } from '@meterup/graphql';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { basicLogger } from 'launchdarkly-js-client-sdk';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { Suspense, useEffect } from 'react';
import { ModalProvider, OverlayProvider } from 'react-aria';
import { createPortal } from 'react-dom';
import { matchPath, Navigate, useLocation } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { instanceOf, match } from 'ts-pattern';

import { titles } from '../generated/titles';
import { useCheckUpdateOnRouteChange } from '../hooks/useCheckUpdateOnRouteChange';
import { useCurrentCompanyFromPathOrNull } from '../hooks/useCurrentCompanyFromPath';
import { useValidateFeatureFlags } from '../hooks/useValidateFeatureFlags';
import {
  LaunchDarklyIdentifyEffect,
  PostHogIdentifyEffect,
  UserIdentityEffect,
} from '../identify_effects';
import { Nav } from '../nav';
import { DefaultCompanyProvider } from '../providers/DefaultCompanyProvider';
import { DefaultCurrentControllerProvider } from '../providers/DefaultControllerProvider';
import { DefaultCurrentNetworkProvider } from '../providers/DefaultNetworkProvider';
import { IdentityDataProvider } from '../providers/IdentityDataProvider';
import { PermissionsProvider } from '../providers/PermissionsProvider';
import { PosthogFeatureFlagsProvider } from '../providers/PostHogFeatureFlagsProvider';
import RoleProvider from '../providers/RoleProvider';
import ThemeProvider from '../providers/ThemeProvider';
import { ZendeskProvider } from '../providers/ZendeskProvider';
import { globalCss, styled } from '../stitches';
import { getRealm, Realm } from '../utils/realm';
import AutoAddMeterEmployeeToCompany from './AutoAddMeterEmployeeToCompany';
import { AutoSaveDefaultCompanyEffect } from './AutoSaveDefaultCompanyEffect';
import { AutoSaveDefaultNetworkEffect } from './AutoSaveDefaultNetworkEffect';
import { EnforceDemoCompanyEffect } from './EnforceDemoCompanyEffect';
import { FatalErrorFallback } from './ErrorFallback/ErrorFallback';
import GlobalMaintenanceKillswitch from './GlobalMaintenanceKillswitch';
import NetworkUUIDToSlugRedirect from './NetworkUUIDRedirect';
import { OverlayableContainer, overlayableRootCSS } from './overlays';
import { FullscreenFallback } from './Placeholders/FullscreenFallback';
import { MainRoutes } from './route_elements';
import { SkipFeatureChecksForOperatorsProvider } from './SkipFeatureChecksProvider';

function retry(count: number, error: unknown) {
  return match(error)
    .with(instanceOf(ResourceNotFoundError), () => false)
    .when(
      (err) => {
        // TODO: Consider making this a DisplayableError of some new kind
        if (err instanceof Error) {
          const graphqlError = getGraphQLError(err);
          return graphqlError?.extensions?.code === GraphQLExtensionErrorCode.Unauthorized;
        }

        return false;
      },
      () => false,
    )
    .otherwise(() => count < 3);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: import.meta.env.REALM === 'local' ? 'always' : undefined,
      retry,
      useErrorBoundary: true,
    },
    mutations: {
      networkMode: import.meta.env.REALM === 'local' ? 'always' : undefined,
    },
  },
});

const injectGlobalStyles = globalCss({
  'html, body, #root': {
    height: '100%',
    width: '100%',
    overscrollBehaviorY: 'none',
    position: 'fixed',
    overflow: 'hidden',
    colorScheme: 'light',

    [darkThemeSelector]: {
      colorScheme: 'dark',
    },
  },
  '#root': overlayableRootCSS,
});

const StyledOverlayProvider = styled(OverlayProvider, OverlayableContainer);

function RedirectDrawerEffect({ children }: { children: React.ReactNode }) {
  const location = useLocation();

  if (location.search.includes('drawer')) {
    const params = new URLSearchParams(location.search);
    const drawer = params.get('drawer');

    return (
      <Navigate
        replace
        to={Nav.makeTo({
          root: location.pathname,
          drawer,
        })}
      />
    );
  }

  return children;
}

function CheckUpdateOnRouteChange() {
  useCheckUpdateOnRouteChange();

  return null;
}

function DashboardAuthorizationProvider({ children }: React.PropsWithChildren<{}>) {
  const companySlug = useCurrentCompanyFromPathOrNull()?.company_slug;

  return <AuthorizationProvider companySlug={companySlug}>{children}</AuthorizationProvider>;
}

function AppProviders({ children }: React.PropsWithChildren<{}>) {
  return (
    <PosthogFeatureFlagsProvider>
      <Router>
        <RedirectDrawerEffect>
          <Nav.Provider>
            <QueryClientProvider client={queryClient}>
              <CheckUpdateOnRouteChange />
              <ThemeProvider>
                <StyledOverlayProvider>
                  <ModalProvider>
                    <GlobalMaintenanceKillswitch>
                      <IdentityDataProvider>
                        <UserIdentityEffect />
                        <PostHogIdentifyEffect />
                        <DashboardAuthorizationProvider>
                          <ZendeskProvider>
                            <DemoModeProvider>
                              <LaunchDarklyIdentifyEffect />
                              <AutoAddMeterEmployeeToCompany>
                                <SkipFeatureChecksForOperatorsProvider>
                                  <DefaultCompanyProvider>
                                    <DefaultCurrentNetworkProvider>
                                      <DefaultCurrentControllerProvider>
                                        <RoleProvider>
                                          <PermissionsProvider>
                                            <EnforceDemoCompanyEffect />
                                            <AutoSaveDefaultNetworkEffect />
                                            <AutoSaveDefaultCompanyEffect />
                                            <NetworkUUIDToSlugRedirect />
                                            <Command.Root>
                                              <Command.Renderer />
                                              {children}
                                            </Command.Root>
                                          </PermissionsProvider>
                                        </RoleProvider>
                                      </DefaultCurrentControllerProvider>
                                    </DefaultCurrentNetworkProvider>
                                  </DefaultCompanyProvider>
                                </SkipFeatureChecksForOperatorsProvider>
                              </AutoAddMeterEmployeeToCompany>
                            </DemoModeProvider>
                          </ZendeskProvider>
                        </DashboardAuthorizationProvider>
                      </IdentityDataProvider>
                    </GlobalMaintenanceKillswitch>
                  </ModalProvider>
                </StyledOverlayProvider>
              </ThemeProvider>
            </QueryClientProvider>
          </Nav.Provider>
        </RedirectDrawerEffect>
      </Router>
    </PosthogFeatureFlagsProvider>
  );
}

// This is a component vs. a hook bc we want it to have access to context from AppProviders
function AutoPageTitle() {
  const company = useCurrentCompanyFromPathOrNull();
  const loc = useLocation();
  const { pathname } = loc;

  useEffect(() => {
    const titleResult = Object.entries(titles).find(([pattern]) => !!matchPath(pattern, pathname));

    const components = ['Meter Dashboard'];
    if (company) {
      components.unshift(company.company_slug);
    }
    if (titleResult) {
      components.unshift(titleResult[1]);
    }

    document.title = components.join(' - ');
  }, [company, pathname]);

  return null;
}

function App() {
  useEffect(() => {
    injectGlobalAttoStyles();
    injectGlobalStyles();
  });

  useSetMaximumScale();
  useValidateFeatureFlags();

  // Render notifications into <body> instead of document root for intended z-indexing with Dialog.
  const portaledNotifications = createPortal(<Notifications />, document.body);

  return (
    <ErrorBoundary fallback={FatalErrorFallback}>
      <Suspense fallback={<FullscreenFallback />}>
        {portaledNotifications}
        <AppProviders>
          <AutoPageTitle />
          <MainRoutes />
        </AppProviders>
        <SpeedInsights />
      </Suspense>
    </ErrorBoundary>
  );
}

const withLaunchDarkly = withLDProvider({
  clientSideID: import.meta.env.LAUNCHDARKLY_CLIENT_ID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  options: {
    logger: basicLogger({ level: getRealm() === Realm.PRODUCTION ? 'none' : 'info' }),
    inspectors: [
      {
        type: 'flag-used',
        name: 'dd-inspector',
        method: (key: string, detail: any) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value);
        },
      },
    ],
  },
});

export default Sentry.withProfiler(withLaunchDarkly(App));
