import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import type { IsNosFeatureEnabledForCompanyQuery } from '../gql/graphql';
import { useShouldDangerouslySkipFeatureChecksForOperators } from '../components/SkipFeatureChecksProvider';
import { graphql } from '../gql';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { useNetworkUUID } from './useNetworkFromPath';

export enum NosFeature {
  SOS = 'v1.config2.sos',
  WOS2 = 'v1.config2.wos',
  COS2 = 'v1.config2.cos',
  POS = 'v1.config2.pos',

  // SOS
  SOSUsesStat = 'v1.config2.sos-uses-stat',
  SOS_PORT_RATE_LIMITING = 'v1.sos.port-rate-limiting',
  SOS_STP_EDGE_PORT = 'v1.sos.stp-edge-port',
  SOS_STP_PATH_AND_PRIORITY = 'v1.sos.stp-path-and-priority',
  SOS_MAX_MAC_ADDRESSES = 'v1.sos.max-mac-addresses',
  SOS_SWITCH_WIDE_MTU = 'v1.sos.switch-wide-mtu',
  SOS_SWITCH_FLIP = 'v1.config2.sos-uses-flip',
  SOS_VOICE_VLAN = 'v1.config2.sos-uses-voice-vlan',
  SOS_8021X = 'v1.config2.sos-8021x',

  // WOS 7 features
  WOS_80211AX = 'v1.wos.80211ax', // 802.11ax for SSIDs
  WOS_80211R = 'v1.wos.80211r', // 802.11r for SSIDs
  WOS_80211W = 'v1.wos.80211w', // 802.11w for SSIDs
  WOS_80211k = 'v1.wos.80211k', // 802.11k for SSIDs
  WOS_80211v = 'v1.wos.80211v', // 802.11v for SSIDs
  WOS_ACS = 'v1.wos.auto-channel-selection', // Auto Channel Selection
  WOS_AUTO_CHANNEL_WIDTH = 'v1.wos.auto-channel-width', // Auto Channel Width
  WOS_AUTO_TRANSMIT_POWER = 'v1.wos.auto-transmit-power', // Auto Transmit Power
  WOS_CLIENT_ASSOCIATION_STEERING = 'v1.wos.client-association-steering', // Client Association Steering for SSIDs
  WOS_CLIENT_ISOLATION = 'v1.wos.client-isolation', // Support SSID client isolation (clients on the same SSID can't communicate with each other)
  WOS_CLIENT_CHANNEL_UTILIZATION = 'v1.wos.client-channel-utilization', // Support for client channel utilization (radioTable on wosClientTable)
  WOS_EAPOL_TEST = 'v1.wos.eapol-test', // EAPOL Test
  WOS_WPA3_PSK = 'v1.wos.wpa3-psk', // WPA3 and WPA3-Transition for SSIDs
  WOS_WPA3_ENTERPRISE = 'v1.wos.wpa3-enterprise', // WPA3-Enterprise for SSIDs
  WOS_DYNAMIC_VLAN = 'v1.wos.dynamic-vlan', // Dynamic vlan based on RADIUS server
  WOS_OPEN_MAC_AUTH_RADIUS = 'v1.wos.open-mac-auth-radius', // Open MAC auth with Radius for SSIDs
  WOS_PACKET_CAPTURES = 'v1.wos.packet-captures', // Packet capture support
  WOS_RADIUS_CHANGE_OF_AUTHORIZATION = 'v1.wos.radius-change-of-authorization', // Radius Change of Authorization for SSIDs
  WOS_RPC_DISCONNECT_CLIENT = 'v1.wos.rpc-disconnect-client', // Send a request to disconnect a client from an AP
  WOS_HOTSPOT_20 = 'v1.wos.hotspot-20',
  WOS_SSID_SCHEDULING = 'v1.wos.ssid-scheduling',
  WOS_AP_HEALTH = 'v1.wos.ap-health',
  WOS_MPSK = 'v1.wos.mpsk', // Support MPSK (Vault by RoamingIQ)

  // COS
  CAPTIVE_PORTAL = 'v1.cos.captive-portal',
  CAPTIVE_PORTAL_AUTH_EVENTS = 'v1.cos.captive-portal.auth-events',
  CAPTIVE_PORTAL_EXCAP = 'v1.cos.captive-portal.external-captive-portal',
  CAPTIVE_PORTAL_ALLOWED_HOSTS = 'v1.cos.captive-portal.allowed-hosts',
  COS_HIGH_AVAILABILITY = 'v1.cos.high-availability',
  DNS_SECURITY = 'v1.cos.dns-security',
  FIREWALL_RULES = 'v1.cos.firewall-rules',
  HOST_MONITORING = 'v1.cos.host-monitoring',
  IPSEC_TUNNELS = 'v1.cos.ipsec-tunnels',
  POLICY_ROUTING = 'v1.cos.policy-routing',
  MDNS_FILTERS = 'v1.cos.mdns-filters',
  RATE_LIMITING = 'v1.cos.rate-limiting',
  RATE_LIMITING_PER_SOURCE = 'v1.cos.rate-limiting.per-source',
  SITE_TO_SITE_VPN = 'v1.cos.site-to-site-vpn',
  LAN_PING = 'v1.lanping',
  ISP_QUALITY = 'v1.cos.isp-quality',
  ISP_AVAILABILITY = 'v1.cos.isp-availability',
  PORT_FORWARD_SUPPORTS_WAN_AND_IP = 'v1.cos.port-forward.wan-and-ip',
  ONE_TO_ONE_NAT = 'v1.cos.one-to-one-nat',
  SPEED_TEST = 'v1.cos.wan-speed-test-20',
  COS_DHCP_OPTIONS_CUSTOM_TYPES = 'v1.cos.dhcp-option-custom-types',
  COS_CUSTOM_DEFAULT_VLAN_PREFIX_LENGTH = 'v1.cos.custom-default-vlan-prefix-length',

  // Console access using challenge-response
  WOSConsoleChallengeResponse = 'v1.wos.console-challenge-response',
  SOSConsoleChallengeResponse = 'v1.sos.console-challenge-response',
  COSConsoleChallengeResponse = 'v1.cos.console-challenge-response',
  POSConsoleChallengeResponse = 'v1.pos.console-challenge-response',
}

const nosKeys = Object.values(NosFeature) as [NosFeature, ...NosFeature[]];

// Only lookup keys that need to be checked at the company level. For now this is just
// v1.cos.site-to-site-vpn.
const companyNosKeys: [NosFeature, ...NosFeature[]] = [NosFeature.SITE_TO_SITE_VPN];

export const isNosFeatureEnabledForNetworkQuery = graphql(`
  query IsNosFeatureEnabledForNetwork($networkUUID: UUID!, $nosFeatureKey: String!) {
    isNosFeatureEnabledForNetwork(networkUUID: $networkUUID, nosFeatureKey: $nosFeatureKey)
  }
`);

export const areNosFeaturesEnabledForNetworkQuery = graphql(`
  query AreNosFeatureEnabledForNetwork($networkUUID: UUID!, $nosFeatureKeys: [String!]!) {
    areNosFeaturesEnabledForNetwork(networkUUID: $networkUUID, nosFeatureKeys: $nosFeatureKeys) {
      key
      enabled
    }
  }
`);

export const isNosFeatureEnabledForCompany = graphql(`
  query IsNosFeatureEnabledForCompany($companySlug: String!, $nosFeatureKey: String!) {
    isNosFeatureEnabledForCompany(companySlug: $companySlug, nosFeatureKey: $nosFeatureKey)
  }
`);

export const areNosFeaturesEnabledForCompanyQuery = graphql(`
  query AreNosFeatureEnabledForCompany($companySlug: String!, $nosFeatureKeys: [String!]!) {
    areNosFeaturesEnabledForCompany(companySlug: $companySlug, nosFeatureKeys: $nosFeatureKeys) {
      key
      enabled
    }
  }
`);

export function useNosFeatureEnabledForNetwork(
  nosFeatureKey: NosFeature,
  networkUUID: string | null,
): boolean {
  const isEnabled =
    useGraphQL(
      isNosFeatureEnabledForNetworkQuery,
      {
        networkUUID: networkUUID as string,
        nosFeatureKey,
      },
      {
        enabled: !!networkUUID,
      },
    ).data?.isNosFeatureEnabledForNetwork ?? false;

  const skipFeatureChecks = useShouldDangerouslySkipFeatureChecksForOperators();

  if (skipFeatureChecks) return true;

  return isEnabled;
}

export type NosFeatureReturnValues<Keys extends [NosFeature, ...NosFeature[]]> = {
  [Idx in keyof Keys]: boolean;
};

export function useNosFeaturesEnabledForNetwork<Keys extends [NosFeature, ...NosFeature[]]>(
  nosFeatureKeys: Keys,
  networkUUID: string | null,
): NosFeatureReturnValues<Keys> {
  const resp = useGraphQL(
    areNosFeaturesEnabledForNetworkQuery,
    {
      networkUUID: networkUUID as string,
      nosFeatureKeys,
    },
    {
      enabled: !!networkUUID,
    },
  ).data?.areNosFeaturesEnabledForNetwork;
  const skipFeatureChecks = useShouldDangerouslySkipFeatureChecksForOperators();

  return useMemo(() => {
    if (skipFeatureChecks) {
      return Array(nosFeatureKeys.length).fill(true);
    }

    const respMap = new Map<string, boolean>(resp?.map(({ key, enabled }) => [key, enabled]));
    return nosFeatureKeys.map((key) => respMap.get(key) ?? false);
  }, [nosFeatureKeys, resp, skipFeatureChecks]) as NosFeatureReturnValues<Keys>;
}

export function useNosFeaturesEnabledForNetworkMap<Keys extends [NosFeature, ...NosFeature[]]>(
  nosFeatureKeys: Keys,
  networkUUID: string | null,
): Record<NosFeature, boolean> {
  const resp = useGraphQL(
    areNosFeaturesEnabledForNetworkQuery,
    {
      networkUUID: networkUUID as string,
      nosFeatureKeys,
    },
    {
      enabled: !!networkUUID,
    },
  ).data?.areNosFeaturesEnabledForNetwork;
  const skipFeatureChecks = useShouldDangerouslySkipFeatureChecksForOperators();

  return useMemo(() => {
    if (skipFeatureChecks) {
      return nosFeatureKeys.reduce((acc, curr) => ({ ...acc, ...{ [curr]: true } }), {});
    }
    return resp
      ? resp.reduce((acc, curr) => ({ ...acc, ...{ [curr.key]: curr.enabled } }), {})
      : {};
  }, [nosFeatureKeys, resp, skipFeatureChecks]) as Record<NosFeature, boolean>;
}

export function useNosFeatureEnabled(nosFeatureKey: NosFeature) {
  const networkUUID = useNetworkUUID();

  return useNosFeatureEnabledForNetwork(nosFeatureKey, networkUUID);
}

export function useNosFeaturesEnabled<Keys extends [NosFeature, ...NosFeature[]]>(
  nosFeatureKeys: Keys,
): NosFeatureReturnValues<Keys> {
  const networkUUID = useNetworkUUID();

  return useNosFeaturesEnabledForNetwork(nosFeatureKeys, networkUUID);
}

export function useNosFeatures(): Record<NosFeature, boolean> {
  const networkUUID = useNetworkUUID();
  return useNosFeaturesEnabledForNetworkMap(nosKeys, networkUUID);
}

export function useNosFeaturesEnabledForCompanyMap<Keys extends [NosFeature, ...NosFeature[]]>(
  nosFeatureKeys: Keys,
  companySlug: string | null,
): Record<NosFeature, boolean> {
  const resp = useGraphQL(
    areNosFeaturesEnabledForCompanyQuery,
    {
      companySlug: companySlug as string,
      nosFeatureKeys,
    },
    {
      enabled: !!companySlug,
    },
  ).data?.areNosFeaturesEnabledForCompany;
  const skipFeatureChecks = useShouldDangerouslySkipFeatureChecksForOperators();

  return useMemo(() => {
    if (skipFeatureChecks) {
      return nosFeatureKeys.reduce((acc, curr) => ({ ...acc, ...{ [curr]: true } }), {});
    }
    return resp
      ? resp.reduce((acc, curr) => ({ ...acc, ...{ [curr.key]: curr.enabled } }), {})
      : {};
  }, [nosFeatureKeys, resp, skipFeatureChecks]) as Record<NosFeature, boolean>;
}

export function useCompanyNosFeatures(): Record<NosFeature, boolean> {
  const companySlug = useCurrentCompany();
  return useNosFeaturesEnabledForCompanyMap(companyNosKeys, companySlug);
}

export function useNosFeatureEnabledForCompany<Key extends NosFeature>(
  nosFeatureKey: Key,
  companySlug: string,
): IsNosFeatureEnabledForCompanyQuery {
  const resp = useGraphQL(
    isNosFeatureEnabledForCompany,
    {
      nosFeatureKey,
      companySlug,
    },
    {
      enabled: !!companySlug,
    },
  ).data;

  const skipFeatureChecks = useShouldDangerouslySkipFeatureChecksForOperators();

  return useMemo(() => {
    if (skipFeatureChecks) {
      return { isNosFeatureEnabledForCompany: true };
    }
    return resp;
  }, [resp, skipFeatureChecks]) as IsNosFeatureEnabledForCompanyQuery;
}
